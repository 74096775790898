import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './AuthContext'; // Import AuthProvider
import GamesPage from './AppsConfiguration/GamesPage';
import GameDetailsPage from './AppsConfiguration/GameDetailsPage';
import LoginPage from './LoginPage';
import ProtectedRoute from './ProtectedRoute'; // Make sure this path is correct
import GlobalNav from './GlobalNav'; // Import GlobalNav
import PortalHome from './PortalHome'; // Import SelectionPage
import Forbidden from './Forbidden'; // Import Forbidden component
import Dummy from './NetworkAdaptersChangelog/Dummy'; // Import Dummy component
import NotFoundPage from './NotFoundPage'; // Your NotFound component
import UnderMaintenance from './UnderMaintenance';


const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
function App() {
    return (
        <GoogleOAuthProvider clientId={clientId}>
            <AuthProvider> {/* Wrap the Routes with AuthProvider */}
                <Router>
                    <GlobalNav /> {/* Place GlobalNav so it renders across all routes */}
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/" element={
                            <ProtectedRoute>
                                <PortalHome />
                            </ProtectedRoute>
                        } />
                        <Route path="/games" element={
                            <ProtectedRoute>
                                {isMaintenanceMode ? <UnderMaintenance /> : <GamesPage />}
                            </ProtectedRoute>
                        }/>
                        <Route path="/game-details/:gameId" element={
                            <ProtectedRoute>
                                {isMaintenanceMode ? <UnderMaintenance /> : <GameDetailsPage />}
                            </ProtectedRoute>
                        }  />
                        <Route path="/network-adapters-changelog" element={
                            <ProtectedRoute>
                                <Dummy />
                            </ProtectedRoute>
                        }  />
                        <Route path="/forbidden" element={<Forbidden />} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </Router>
            </AuthProvider>
        </GoogleOAuthProvider>
    );
}

export default App;
