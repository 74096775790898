import React from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import './GlobalNav.css'; // Import the stylesheet for GlobalNav

const GlobalNav = () => {
    const { isAuthenticated, logout, userName, userRoleName } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/');
    };
    
    const navigateHome = () => {
        navigate('/');
    };
    
    return (
        <nav>
            <button onClick={navigateHome} className="home-button">
                <i className="material-icons">home</i>
            </button>
            {isAuthenticated && (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <span className="user-info">{userRoleName} @ {userName}</span>
                    <button onClick={handleLogout} className="logout-button">
                        <i className="material-icons">exit_to_app</i>
                        Logout
                    </button>
                </div>
            )}
        </nav>
    );
};

export default GlobalNav;
