// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PortalHome.css */
.portal-home {
    text-align: center;
}

.card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
}

.card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    color: inherit;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.card img {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
}

.card h2 {
    margin: 10px 0 0;
    font-size: 1.2em;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.card.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
}

.card.disabled:hover {
    transform: none;
    box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/PortalHome.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,2DAA2D;IAC3D,SAAS;IACT,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,qBAAqB;IACrB,cAAc;IACd,wCAAwC;IACxC,2CAA2C;AAC/C;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,yCAAyC;AAC7C;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["/* PortalHome.css */\n.portal-home {\n    text-align: center;\n}\n\n.card-grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n    gap: 20px;\n    padding: 20px;\n}\n\n.card {\n    background: white;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    padding: 20px;\n    text-align: center;\n    text-decoration: none;\n    color: inherit;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);\n    transition: transform 0.2s, box-shadow 0.2s;\n}\n\n.card img {\n    width: 40px;\n    height: 40px;\n    margin-bottom: 10px;\n}\n\n.card h2 {\n    margin: 10px 0 0;\n    font-size: 1.2em;\n}\n\n.card:hover {\n    transform: translateY(-5px);\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);\n}\n\n.card.disabled {\n    opacity: 0.6;\n    cursor: not-allowed;\n    pointer-events: none;\n}\n\n.card.disabled:hover {\n    transform: none;\n    box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
