class MediationProperties {
    constructor(mediationName) {
        this.mediationName = mediationName;
        this.apiKey = '';
        this.footerId = '';
        this.mrecId = '';
        this.interstitialId = '';
        this.fastInterstitialId = '';
        this.rewardedId = '';
        this.fastRewardedId = '';
    }

    // Method to check if any properties are 'active' (non-empty), excluding apiKey
    isActive() {
        // Check each property, returning true if any property is non-empty
        return !!(
            this.footerId ||
            this.mrecId ||
            this.interstitialId ||
            this.fastInterstitialId ||
            this.rewardedId ||
            this.fastRewardedId
        );
    }


    // Example method to display info about the config
    displayConfig() {
        console.log(`API Key: ${this.apiKey}`);
    }
}

function isNullOrEmpty(data) {
    // Check if ironSourceMediation exists and if footerId is not null or empty
    return !data || data.trim() === '';
}

function CheckMediation(mediation, failures) {
    if (mediation.isActive() !== !isNullOrEmpty(mediation.apiKey))
            failures.push(mediation.mediationName, 'is either missing api key or ad units');
}

function CheckAmazonApiKey(admobAmazon, maxAmazonMediation, ironSourceAmazonMediation, failures) {

    if (admobAmazon.isActive() && maxAmazonMediation.isActive())
        if (admobAmazon.apiKey !== maxAmazonMediation.apiKey)
            failures.push('Admob Amazon API Key does not match Max Amazon API Key', admobAmazon.apiKey, maxAmazonMediation.apiKey);

    if (admobAmazon.isActive() && ironSourceAmazonMediation.isActive())
        if (admobAmazon.apiKey !== ironSourceAmazonMediation.apiKey)
            failures.push('Admob Amazon API Key does not match IronSource Amazon API Key', admobAmazon.apiKey, ironSourceAmazonMediation.apiKey);

    if (maxAmazonMediation.isActive() && ironSourceAmazonMediation.isActive())
        if (maxAmazonMediation.apiKey !== ironSourceAmazonMediation.apiKey)
            failures.push('Max Amazon API Key does not match IronSource Amazon API Key',    maxAmazonMediation.apiKey, ironSourceAmazonMediation.apiKey);
}

function CheckAppsflyerTrackerId(appsflyerTrackerId, companyName, failures) {
    // switch (companyName) {
    //     case 'Fiogonia': 
    //         if (appsflyerTrackerId !== 'vqhKDkKBRgz4HQREgBEg7Y')
    //             failures.push('Appsflyer Tracker Id is incorrect');
    //     case 'Fiogonia':
    //         if (appsflyerTrackerId !== '123456789')
    //             failures.push('Appsflyer Tracker Id is incorrect');
    //     case 'Fiogonia':
    //         if (appsflyerTrackerId !== '123456789')
    //             failures.push('Appsflyer Tracker Id is incorrect');
    if (appsflyerTrackerId !== 'vqhKDkKBRgz4HQREgBEg7Y')
        failures.push('Appsflyer Tracker Id is incorrect');
}

export const ValidateGameConfig = (gameConfigs) => {
    let AppId = '';
    let appsflyerTracker = '';
    let ShareUrl = '';
    let failures = [];
    let companyName = '';
    let admobMediation  = new MediationProperties('Admob');
    let admobAmazon  = new MediationProperties('Admob Amazon');
    let maxMediation  = new MediationProperties('Max');
    let maxAmazonMediation  = new MediationProperties('Max Amazon');
    let ironSourceMediation  = new MediationProperties('IronSource');
    let ironSourceAmazonMediation  = new MediationProperties('IronSource Amazon');
    
    gameConfigs.forEach(config => {
        companyName = config.company_name;
        if (config.provider_id === 2){
            switch (config.config_key_id) {
                case 16:
                    admobMediation.apiKey = config.config_value;
                    break;
                case 2:
                    admobMediation.footerId = config.config_value;
                    break;
                case 3:
                    admobMediation.mrecId = config.config_value;
                    break;
                case 4:
                    admobMediation.interstitialId = config.config_value;
                    break;
                case 17:
                    admobMediation.fastInterstitialId = config.config_value;
                    break;
                case 5:
                    admobMediation.rewardedId = config.config_value;
                    break;
                case 6:
                    admobMediation.fastRewardedId = config.config_value;
                    break;
            }
        }
        if (config.provider_id === 3){
            switch (config.config_key_id) {
                case 16:
                    admobAmazon.apiKey = config.config_value;
                    break;
                case 2:
                    admobAmazon.footerId = config.config_value;
                    break;
                case 3:
                    admobAmazon.mrecId = config.config_value;
                    break;
                case 4:
                    admobAmazon.interstitialId = config.config_value;
                    break;
                case 17:
                    admobAmazon.fastInterstitialId = config.config_value;
                    break;
                case 5:
                    admobAmazon.rewardedId = config.config_value;
                    break;
                case 6:
                    admobAmazon.fastRewardedId = config.config_value;
                    break;
            }
        }
        if (config.provider_id === 4){
            switch (config.config_key_id) {
                case 16:
                    maxMediation.apiKey = config.config_value;
                    break;
                case 2:
                    maxMediation.footerId = config.config_value;
                    break;
                case 3:
                    maxMediation.mrecId = config.config_value;
                    break;
                case 4:
                    maxMediation.interstitialId = config.config_value;
                    break;
                case 17:
                    maxMediation.fastInterstitialId = config.config_value;
                    break;
                case 5:
                    maxMediation.rewardedId = config.config_value;
                    break;
                case 6:
                    maxMediation.fastRewardedId = config.config_value;
                    break;
            }
        }
        if (config.provider_id === 5){
            switch (config.config_key_id) {
                case 16:
                    maxAmazonMediation.apiKey = config.config_value;
                    break;
                case 2:
                    maxAmazonMediation.footerId = config.config_value;
                    break;
                case 3:
                    maxAmazonMediation.mrecId = config.config_value;
                    break;
                case 4:
                    maxAmazonMediation.interstitialId = config.config_value;
                    break;
                case 17:
                    maxAmazonMediation.fastInterstitialId = config.config_value;
                    break;
                case 5:
                    maxAmazonMediation.rewardedId = config.config_value;
                    break;
                case 6:
                    maxAmazonMediation.fastRewardedId = config.config_value;
                    break;
            }
        }
        if (config.provider_id === 6){
            switch (config.config_key_id) {
                case 16:
                    ironSourceMediation.apiKey = config.config_value;
                    break;
                case 2:
                    ironSourceMediation.footerId = config.config_value;
                    break;
                case 3:
                    ironSourceMediation.mrecId = config.config_value;
                    break;
                case 4:
                    ironSourceMediation.interstitialId = config.config_value;
                    break;
                case 17:
                    ironSourceMediation.fastInterstitialId = config.config_value;
                    break;
                case 5:
                    ironSourceMediation.rewardedId = config.config_value;
                    break;
                case 6:
                    ironSourceMediation.fastRewardedId = config.config_value;
                    break;
            }
        }
        if (config.provider_id === 7){
            switch (config.config_key_id) {
                case 16:
                    ironSourceAmazonMediation.apiKey = config.config_value;
                    break;
                case 2:
                    ironSourceAmazonMediation.footerId = config.config_value;
                    break;
                case 3:
                    ironSourceAmazonMediation.mrecId = config.config_value;
                    break;
                case 4:
                    ironSourceAmazonMediation.interstitialId = config.config_value;
                    break;
                case 17:
                    ironSourceAmazonMediation.fastInterstitialId = config.config_value;
                    break;
                case 5:
                    ironSourceAmazonMediation.rewardedId = config.config_value;
                    break;
                case 6:
                    ironSourceAmazonMediation.fastRewardedId = config.config_value;
                    break;
            }
        }
        
        if (config.provider_id === 1){
            switch (config.config_key_id) {
                case 7:
                    AppId = config.config_value;
                    break;
                case 19:
                    ShareUrl = config.config_value;
                    break;
                case 9:
                    appsflyerTracker = config.config_value;
                    break;
            }
        }
    });
    
    if (isNullOrEmpty(admobMediation.apiKey))
        failures.push('Admob API Key is missing');
    
    if (isNullOrEmpty(AppId))
        failures.push('AppId is missing');

    // if (isNullOrEmpty(ShareUrl))
    //     failures.push('ShareUrl is missing');

    CheckMediation(admobAmazon, failures);
    CheckMediation(maxMediation, failures);
    CheckMediation(maxAmazonMediation, failures);
    CheckMediation(ironSourceMediation, failures);
    CheckMediation(ironSourceAmazonMediation, failures);

    CheckAmazonApiKey(admobAmazon, maxAmazonMediation, ironSourceAmazonMediation, failures);
    
    CheckAppsflyerTrackerId(appsflyerTracker, companyName, failures);
    
    return failures;
};