import React from 'react';

const Dummy = () => {
    return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
            <h1>Network adapters</h1>
        </div>
    );
};

export default Dummy;