// PortalHome.js
import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import './PortalHome.css'; // Ensure you create this CSS file for styling
import gamesIcon from './icons/games.svg'; // Add appropriate paths for your icons
import anotherIcon from './icons/networks.svg'; // Example for another icon
import { CanAccessRoute } from './permissions'; // Import utilities

const PortalHome = () => {
    useEffect(() => {
        document.title = "FM Games Portal"; // Set the title you want for this page
    }, []); // Empty dependency array means this runs once after the component mounts

    const cards = [
        {
            title: 'Games',
            icon: gamesIcon,
            link: '/games'
        },
        {
            title: 'Network Adapters Changelog',
            icon: anotherIcon,
            link: '/network-adapters-changelog'
        }
        // Add more cards as needed
    ];

    return (
        <div className="portal-home">
            <h1>Welcome to FM Games Portal! Choose your destination:</h1>
            <div className="card-grid">
                {cards.map((card, index) => {
                    const isDisabled = !CanAccessRoute(card.link);
                    return (
                        <Link
                            key={index}
                            to={isDisabled ? '#' : card.link}
                            className={`card ${isDisabled ? 'disabled' : ''}`}
                            onClick={e => isDisabled && e.preventDefault()} // Prevent click if disabled
                        >
                            <img src={card.icon} alt={`${card.title} Icon`} />
                            <h2>{card.title}</h2>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default PortalHome;
