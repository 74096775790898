import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Make sure to install axios if not already done
import { useAuth } from './AuthContext';
import googleLogo from './icons/web_neutral_rd_na.svg'; // Make sure to add the path to your Google logo image
import './LoginPage.css'; 

function LoginPage() {
    const navigate = useNavigate();
    const { login } = useAuth();

    // Adjusted for authorization code flow
    const handleSuccess = async (response) => {
        // Now, the response should contain an authorization code
        const authorizationCode = response.code;

        try {
            // Send the authorization code to your backend to exchange for tokens
            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/google`, {
                code: authorizationCode,
            });

            // Assuming your backend response includes an id_token or a custom session token
            // Adjust based on your backend response. For example, using id_token:
            login(data.idToken, data.username, data.roleId, data.roleName);
            
            console.log("Logged in successfully! Redirecting to /games...");
            // Redirect after successful login
            navigate('/');
        } catch (error) {
            console.error('Token exchange failed:', error);
        }
    };

    const handleError = () => {
        console.log('Login Failed');
    };

    // Specify the flow type for authorization code flow
    const initiateLogin = useGoogleLogin({
        onSuccess: handleSuccess,
        onError: handleError,
        flow: 'auth-code', // Specify the flow type
    });

    return (
        <div className="landing-page">
            <h1>FM Games Portal</h1>
            <button onClick={() => initiateLogin()} className="login-button">
                <img src={googleLogo} alt="Google" style={{marginRight: 10, width: 20, height: 20}}/>
                Sign in with Google
            </button>
        </div>
    );
}

export default LoginPage;
