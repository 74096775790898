// Forbidden.js
import React from 'react';
import { Link } from 'react-router-dom';

const Forbidden = () => {
    return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
            <h1>403 - Forbidden</h1>
            <p>You do not have permission to view this page.</p>
            <Link to="/portal-home">Go back to Home</Link>
        </div>
    );
};

export default Forbidden;
