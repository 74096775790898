import React, { useState, useEffect } from 'react';
import './GameForm.css'; // Adjust the path based on your project structure

const GameForm = ({ gameId, onSuccess, closeFormModal }) => {
    const [gameShortcode, setGameShortcode] = useState('');
    const [gameName, setGameName] = useState('');
    const [companies, setCompanies] = useState([]);
    const [platforms, setPlatforms] = useState([]); // Use platforms instead of providers
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedPlatform, setSelectedPlatform] = useState(''); // Platform instead of provider
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Fetch companies and platforms for dropdowns
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/companies`)
            .then(response => response.json())
            .then(data => setCompanies(data));

        fetch(`${process.env.REACT_APP_API_URL}/platforms`) // Fetching platforms
            .then(response => response.json())
            .then(data => setPlatforms(data));
    }, []);

    // Fetch game details for editing
    useEffect(() => {
        if (gameId) {
            fetch(`${process.env.REACT_APP_API_URL}/games/${gameId}`)
                .then(response => response.json())
                .then(data => {
                    setGameShortcode(data.game_shortcode);
                    setGameName(data.game_name);
                    setSelectedCompany(data.company_id);
                    setSelectedPlatform(data.platform_id); // Set selected platform
                })
                .catch(error => console.error('Error fetching game details:', error));
        } else {
            // Reset form if not editing
            setGameShortcode('');
            setGameName('');
            setSelectedCompany('');
            setSelectedPlatform('');
        }
    }, [gameId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the submit button

        // Corrected URL formation
        const url = `${process.env.REACT_APP_API_URL}/games${gameId ? `/${gameId}` : ''}`;
        const method = gameId ? 'PUT' : 'POST';

        fetch(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                game_shortcode: gameShortcode, // Assuming this is the correct variable name
                game_name: gameName,
                company_id: selectedCompany,
                platform_id: selectedPlatform
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Success:', data);
                closeFormModal();
                onSuccess(); // Refresh the list, close the modal
            })
            .catch(error => {
                console.error('Error:', error);
            })
            .finally(() => setIsSubmitting(false)); // Re-enable the submit button regardless of outcome
    };

    return (

            
        <form onSubmit={handleSubmit}>
            <div className="game-form-title">
                {/* Conditionally render shortcode as an input or just text */}
                {gameId ? (
                    <h1>{gameShortcode}</h1>
                ) : (
                    <div className="form-group">
                        <label htmlFor="game-shortcode" className="form-label">Shortcode:</label>
                        <input
                            type="text"
                            id="game-shortcode"
                            value={gameShortcode}
                            onChange={e => setGameShortcode(e.target.value)}
                            className="form-input"
                        />
                    </div>
                )}
            </div>
            <div className="form-group">
                <label htmlFor="game-name" className="form-label">Game Name:</label>
                <input
                    type="text"
                    id="game-name"
                    value={gameName}
                    onChange={e => setGameName(e.target.value)}
                    className="form-input input-field"
                />
            </div>
            <div className="form-group">
                <label htmlFor="company" className="form-label">Company:</label>
                <select
                    value={selectedCompany}
                    onChange={e => setSelectedCompany(e.target.value)}
                    className="form-input">
                    <option value="">Select Company</option>
                    {/* Initial option prompting user selection */}
                    {companies.map((company) => (
                        <option key={company.company_id} value={company.company_id}>
                            {company.company_name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="platform" className="form-label">Platform:</label>
                <select
                    value={selectedPlatform}
                    onChange={e => setSelectedPlatform(e.target.value)}
                    className="form-input">
                    <option value="">Select Platform</option>
                    {/* Similar initial option for platforms */}
                    {platforms.map((platform) => (
                        <option key={platform.platform_id} value={platform.platform_id}>
                            {platform.platform_name}
                        </option>
                    ))}
                </select>
            </div>
            <br></br>
            <div className="form-actions">
                <button type="submit" disabled={isSubmitting} className="submit-btn">Submit</button>
                <button type="button" onClick={closeFormModal} className="cancel-btn">Cancel</button>
            </div>
        </form>
    );
};

export default GameForm;
