// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}


.login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 24px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif; /* Google uses Roboto font, make sure it's loaded in your app */
    color: #5F6368; /* Text color used by Google */
    background-color: #FFFFFF; /* White background */
    border: 1px solid #4285F4; /* Google blue border */
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0,0,0,0.15);
    transition: box-shadow 0.2s, background-color 0.2s;
}

.login-button img {
    margin-right: 10px; /* Space between logo and text */
}

.login-button:hover {
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
    background-color: #F8F9FA; /* Light grey background on hover */
}`, "",{"version":3,"sources":["webpack://./src/LoginPage.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iCAAiC,EAAE,+DAA+D;IAClG,cAAc,EAAE,8BAA8B;IAC9C,yBAAyB,EAAE,qBAAqB;IAChD,yBAAyB,EAAE,uBAAuB;IAClD,kBAAkB;IAClB,eAAe;IACf,sCAAsC;IACtC,kDAAkD;AACtD;;AAEA;IACI,kBAAkB,EAAE,gCAAgC;AACxD;;AAEA;IACI,qCAAqC;IACrC,yBAAyB,EAAE,mCAAmC;AAClE","sourcesContent":["\n.landing-page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n}\n\n\n.login-button {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 10px 24px;\n    font-size: 16px;\n    font-weight: 500;\n    font-family: 'Roboto', sans-serif; /* Google uses Roboto font, make sure it's loaded in your app */\n    color: #5F6368; /* Text color used by Google */\n    background-color: #FFFFFF; /* White background */\n    border: 1px solid #4285F4; /* Google blue border */\n    border-radius: 4px;\n    cursor: pointer;\n    box-shadow: 0 2px 4px rgba(0,0,0,0.15);\n    transition: box-shadow 0.2s, background-color 0.2s;\n}\n\n.login-button img {\n    margin-right: 10px; /* Space between logo and text */\n}\n\n.login-button:hover {\n    box-shadow: 0 2px 6px rgba(0,0,0,0.2);\n    background-color: #F8F9FA; /* Light grey background on hover */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
