import React from 'react';

const UnderMaintenance = () => {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Under Maintenance</h1>
            <p>We're currently improving our service. Please check back later.</p>
        </div>
    );
};

export default UnderMaintenance;
