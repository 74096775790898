import React, { useState, useEffect } from 'react';

const Tooltip = ({ children, content }) => {
    const [visible, setVisible] = useState(false);

    const toggleVisibility = (event) => {
        event.stopPropagation(); // Prevent event from bubbling up
        setVisible(!visible);
    };

    const closeTooltip = (event) => {
        event.stopPropagation(); // Prevent event from bubbling up
        setVisible(false);
    };

    // Add event listener for the Escape key
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) setVisible(false);  // keyCode 27 is the Escape key
        };

        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <span onClick={toggleVisibility} style={{ cursor: 'pointer' }}>
                {children}
            </span>
            {visible && (
                <div style={{
                    position: 'absolute',
                    border: '1px solid #FFD700',
                    backgroundColor: '#FFF8DC',
                    color: '#555',
                    padding: '10px 10px 10px 10px', // Padding inside the tooltip
                    zIndex: 100,
                    top: '100%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                    minWidth: '250px',  // Increased minimum width
                    maxWidth: '400px',  // Maximum width if needed
                    textAlign: 'left',
                    fontSize: '0.9em',
                    lineHeight: '1.4',
                    overflow: 'hidden',  // Hide overflow
                    paddingTop: '20px'  // Increased padding-top to avoid overlap with the close button
                }}>
                    <strong><i className="material-icons" style={{ verticalAlign: 'middle', color: '#DAA520' }}>warning</i> Warning:</strong> {content}
                    <span style={{ position: 'absolute', top: '5px', right: '5px', cursor: 'pointer' }} onClick={closeTooltip}>
                        <i className="material-icons">close</i>
                    </span>
                </div>
            )}
        </div>
    );
};

export default Tooltip;
