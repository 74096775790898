import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRoleId, setUserRoleId] = useState(null); // State to store user role
    const [userRoleName, setUserRoleName] = useState(null); // State to store user role
    const [isInitializing, setIsInitializing] = useState(true); // Added initializing state
    const [userName, setUserName] = useState(null); // Added for user name

    useEffect(() => {
        const token = localStorage.getItem('userToken');
        const roleId = localStorage.getItem('userRoleId'); // Retrieve role from local storage
        const roleName = localStorage.getItem('userRoleName'); // Retrieve role from local storage
        const userName = localStorage.getItem('userName'); // Retrieve user name from local storage

        if (token) {
            setIsAuthenticated(true);
            setUserRoleId(Number(roleId)); // Set the role in state if token exists
            setUserRoleName(roleName);
            setUserName(userName); // Set user name in state
        }
        setIsInitializing(false); // Set initializing to false after checking token
    }, []);

    const login = (token, userName, roleId, roleName) => {
        localStorage.setItem('userToken', token);
        localStorage.setItem('userName', userName);
        localStorage.setItem('userRoleId', roleId); // Store role in local storage
        localStorage.setItem('userRoleName', roleName); // Store role in local storage
        setIsAuthenticated(true);
        setUserRoleId(roleId); // Update role state
        setUserRoleName(roleName);
        setUserName(userName); // Update user name state
    };

    const logout = () => {
        localStorage.removeItem('userToken');
        localStorage.removeItem('userName');
        localStorage.removeItem('userRoleId'); // Remove role from local storage
        localStorage.removeItem('userRoleName'); // Remove role from local storage

        setIsAuthenticated(false);
        setUserRoleId(null); // Reset role state
        setUserRoleName(null);
        setUserName(null); // Update user name state
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, userRoleId, userRoleName, userName, isInitializing, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
