import { useAuth } from './AuthContext'; // Ensure the correct path

const ACTIONS = {
    CREATE: 'create',
    DELETE: 'delete',
    EDIT: 'edit'
};

const permissions = {
    admin: {
        routes: [
            '/',
            '/games',
            '/game-details/:gameId',
            '/network-adapters-changelog'
        ],
        actions: [
            ACTIONS.CREATE,
            ACTIONS.DELETE,
            ACTIONS.EDIT
        ]
    },
    user: {
        routes: [
            '/',
            '/games',
            '/game-details/:gameId'
        ],
        actions: [
            
        ]
    }
};

const CanPerformAction = (action) => {
    const { userRoleName } = useAuth();
    return permissions[userRoleName]?.actions.includes(action);
};

const CanAccessRoute = (route) => {
    const { userRoleName } = useAuth();
    return permissions[userRoleName]?.routes.includes(route);
};


export { ACTIONS, permissions, CanPerformAction, CanAccessRoute };
