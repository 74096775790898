// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/icon?family=Material+Icons);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
    width: 100%;
    z-index: 1000;
    background: var(--secondary-color); /* Optional background */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional shadow */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.user-info {
    margin-right: 20px; /* Space before the logout button */
    color: var(--primary-color);
    font-weight: bold;
}

.logout-button {
    display: flex;
    align-items: center;
    padding: 5px 20px;
    margin-right: 20px; /* Right margin added here */
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.logout-button:hover {
    background-color: #3A0F3D;
}

.material-icons {
    vertical-align: middle;
    margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/GlobalNav.css"],"names":[],"mappings":"AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,aAAa;IACb,WAAW;IACX,aAAa;IACb,kCAAkC,EAAE,wBAAwB;IAC5D,qCAAqC,EAAE,oBAAoB;IAC3D,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB,EAAE,mCAAmC;IACvD,2BAA2B;IAC3B,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB,EAAE,4BAA4B;IAChD,sCAAsC;IACtC,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;IACtB,iBAAiB;AACrB","sourcesContent":["@import url('https://fonts.googleapis.com/icon?family=Material+Icons');\n\nnav {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    padding: 10px;\n    width: 100%;\n    z-index: 1000;\n    background: var(--secondary-color); /* Optional background */\n    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional shadow */\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.home-button {\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n}\n\n.user-info {\n    margin-right: 20px; /* Space before the logout button */\n    color: var(--primary-color);\n    font-weight: bold;\n}\n\n.logout-button {\n    display: flex;\n    align-items: center;\n    padding: 5px 20px;\n    margin-right: 20px; /* Right margin added here */\n    background-color: var(--primary-color);\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: 16px;\n    transition: background-color 0.3s ease;\n}\n\n.logout-button:hover {\n    background-color: #3A0F3D;\n}\n\n.material-icons {\n    vertical-align: middle;\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
