import React, { useState, useEffect } from 'react';
import GameForm from './GameForm';
import { useNavigate } from 'react-router-dom';
import './GamesPage.css';
import {useAuth} from "../AuthContext";
import { ACTIONS, CanPerformAction } from '../permissions'; // Import utilities and constants
import {ValidateGameConfig} from './ValidateGameConfiguration';
import Tooltip from "../Tooltip"; // Import the validation function
import firebaseLogo from '../icons/firebase.svg'; // Make sure to add the path to your Google logo image


const GamesPage = () => {
    const navigate = useNavigate();
    const [games, setGames] = useState([]);
    const [gamesConfig, setGamesConfig] = useState([]);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [editingGameId, setEditingGameId] = useState(null);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [deleteGameId, setDeleteGameId] = useState(null);
    const {userRoleName} = useAuth(); // Get user role from context
    // Check if the user has the 'create' action
    // Use canPerformAction directly without needing to pass userRoleName
    const canCreate = CanPerformAction(ACTIONS.CREATE);
    const canDelete = CanPerformAction(ACTIONS.DELETE);
    const canEdit = CanPerformAction(ACTIONS.EDIT);
    
    useEffect(() => {
        document.title = 'Apps Config - Games List';
        fetchGames();
    }, []);

    const fetchGamesConfig = (gamesData) => {
        fetch(`${process.env.REACT_APP_API_URL}/gamesValidationConfig`)
            .then(response => response.json())
            .then(data => {
                setGamesConfig(data);
                validateGames(data, gamesData);
            })
            .catch(error => console.error('Error fetching games config:', error));
    };


    const fetchGames = () => {
        fetch(`${process.env.REACT_APP_API_URL}/games`)
            .then(response => response.json())
            .then(data => {
                setGames(data);
                fetchGamesConfig(data);
            })
            .catch(error => console.error('Error fetching games:', error));
    };

    const validateGames = (configData, gamesData) => {
        const gameValidationResults = gamesData.map(game => {
            const gameConfigs = configData.filter(config => config.game_id === game.game_id);
            const failures = ValidateGameConfig(gameConfigs);
            return { ...game, validationFailures: failures };
        });
        setGames(gameValidationResults);
    };
    
    
    
    const handleDeleteConfirm = (gameId) => {
        setIsConfirmModalOpen(true);
        setDeleteGameId(gameId);
    };

    const confirmDelete = () => {
        if (deleteGameId) {
            fetch(`${process.env.REACT_APP_API_URL}/games/${deleteGameId}`, { method: 'DELETE' })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to delete the game');
                    }
                    return response.json();
                })
                .then(() => {
                    fetchGames(); // Refresh the list
                    console.log('Game deleted successfully');
                })
                .catch(error => {
                    console.error('Error deleting game:', error);
                });
        }
        setIsConfirmModalOpen(false);
        setDeleteGameId(null);
    };

    const handleRowClick = (gameId) => {
        navigate(`/game-details/${gameId}`);
    };

    const openFormModal = (gameId) => {
        setIsFormModalOpen(true);
        setEditingGameId(gameId);
    };

    const closeFormModal = () => {
        setIsFormModalOpen(false);
        setEditingGameId(null);
    };

    return (
        <>
            <header className="header-container-center">
                <h1>Games List</h1>
            </header>
            {isFormModalOpen && (
                <div className="modal">
            <div className="modal-content">
                        <span className="close" onClick={closeFormModal}>&times;</span>
                        <GameForm gameId={editingGameId} onSuccess={fetchGames} closeFormModal={closeFormModal}/>
                    </div>
                </div>
            )}
            {isConfirmModalOpen && (
                <div className="modal">
                    <div className="confirmation-modal-content">
                        <span className="close" onClick={() => setIsConfirmModalOpen(false)}>&times;</span>
                        <h2>Are you sure you want to delete this game?</h2>
                        <div className="confirmation-modal-button-container">
                            <button className="confirmation-modal-confirm-button" onClick={confirmDelete}>Yes, Delete
                            </button>
                            <button className="confirmation-modal-cancel-button"
                                    onClick={() => setIsConfirmModalOpen(false)}>Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <table className="table">
                <thead>
                <tr className="tr">
                    <th>Shortcode</th>
                    <th>Name</th>
                    <th>Company</th>
                    <th>Platform</th>
                    <th className="th-actions actions-column">Actions</th>
                    {canCreate && ( // Only render if user is admin
                        <th className="add-game-column">
                            <button onClick={() => openFormModal(null)} className="button-icon add-game-btn"
                                    aria-label="Add new game">
                                <svg viewBox="0 0 24 24" width="24px" height="24px" fill="#00FF00">
                                    <path
                                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v4H7v2h4v4h2v-4h4v-2h-4V7z"/>
                                </svg>
                            </button>
                        </th>
                    )}
                </tr>
                </thead>
                <tbody>
                {games.map(game => (
                    <tr key={game.game_id} onClick={() => handleRowClick(game.game_id)} className="tr cursor-pointer">
                        <td className="td">
                            {game.game_shortcode}
                            {game.validationFailures && game.validationFailures.length > 0 && (
                                <Tooltip content={game.validationFailures.join("\n")}>
        <span className="warning-icon" title={game.validationFailures.join("\n")}>
            <i className="material-icons" style={{color: 'red'}}>warning</i>
        </span>
                                </Tooltip>
                            )}
                        </td>
                        <td className="td"><b>{game.game_name}</b> {game.company_name} {game.platform_name}</td>
                        <td className="td">{game.company_name}</td>
                        <td className="td">{game.platform_name}</td>
                        <td className="action-buttons">
                            {canEdit && (
                                <button onClick={(e) => {
                                    e.stopPropagation();
                                    openFormModal(game.game_id);
                                }} className="edit-btn">
                                    <i className="material-icons">edit</i>
                                </button>
                            )}
                            {canDelete && (
                                <button onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteConfirm(game.game_id);
                                }} className="delete-btn">
                                    <i className="material-icons">delete</i>
                                </button>
                            )}
                            <button onClick={(e) => {
                                e.stopPropagation(); // Prevent row click event
                                window.open(`/game-details/${game.game_id}`, '_blank');
                            }} className="new-tab-btn">
                                <i className="material-icons">launch</i>
                            </button>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                window.open(`https://console.firebase.google.com/project/${game.firebase_project_id}`, '_blank');
                            }} className="new-tab-btn">
                                <img src={firebaseLogo} alt="Firebase Console" style={{width: 30, height: 30}}/>
                            </button>
                        </td>
                    </tr>
                ))}

                </tbody>
            </table>

        </>
    );
};

export default GamesPage;
