import React, { useEffect, useState } from 'react';
import './GameDetailsPage.css'; // Import the CSS file
import { useParams, useNavigate } from 'react-router-dom';
import {ACTIONS, CanPerformAction} from "../permissions";

const GameDetailsPage = () => {
    const { gameId } = useParams();
    const [configurations, setConfigurations] = useState([]);
    const [gameDetails, setGameDetails] = useState({ game_name: '', company_name: '', platform_name: '' });
    const [focusedKey, setFocusedKey] = useState(null);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [editedConfigurations, setEditedConfigurations] = useState([]);
    const [showHistoryPopup, setShowHistoryPopup] = useState(false);
    const [currentHistory, setCurrentHistory] = useState([]);
    const [isSaving, setIsSaving] = useState(false);


    const canEdit = CanPerformAction(ACTIONS.EDIT);

    const handleFocus = (keyName) => {
        setFocusedKey(keyName);
    };

    const handleBlur = () => {
        setFocusedKey(null);
    };

    const navigate = useNavigate();


    const fetchConfigurations = () => {
        fetch(`${process.env.REACT_APP_API_URL}/game-config/${gameId}`)
            .then(response => response.json())
            .then(data => {
                if (data.length > 0) {
                    const firstItem = data[0];
                    setGameDetails({
                        game_name: firstItem.game_name,
                        company_name: firstItem.company_name,
                        platform_name: firstItem.platform_name
                    });

                    document.title = `Apps Config - ${firstItem.game_name} ${firstItem.company_name} ${firstItem.platform_name}`;

                    const configurationsWithEditInfo = data.map(config => ({
                        ...config,
                        game_name: config.game_name,
                        originalValue: config.config_value,
                        editedValue: config.config_value,
                        isEdited: false,
                        provider_id: config.provider_id,
                        config_key_id: config.config_key_id,
                    }));

                    setConfigurations(configurationsWithEditInfo);
                }
            })
            .catch(error => console.error('Error fetching game configuration:', error));
    };


    useEffect(() => {
        fetchConfigurations();

    }, [gameId]); // Fetch configurations when the component mounts or gameId changes

    // History Modal component
    function HistoryPopup({ isOpen, history, onClose }) {
        // Start with the first entry (latest change) because history is sorted DESC by changed_on
        const [currentIndex, setCurrentIndex] = useState(0);

        // Navigate to older history entries
        const handlePrev = () => {
            if (currentIndex < history.length - 1) {
                setCurrentIndex(currentIndex + 1); // Go to older change
            }
        };

        // Navigate to newer history entries
        const handleNext = () => {
            if (currentIndex > 0) {
                setCurrentIndex(currentIndex - 1); // Go to newer change
            }
        };

        if (!isOpen || history.length === 0) return null;

        const currentItem = history[currentIndex];

        return (
            <div className="popup-overlay">
                <div className="popup-content">
                    <h2>Edit History</h2>
                    <div className="change-details">
                        <div className="username">{currentItem.changed_by}</div>
                        <div className="timestamp">{new Date(currentItem.changed_on).toLocaleString()}</div>
                        <div className="change-description">
                            Replaced: "{currentItem.old_value}" with "{currentItem.new_value}"
                        </div>
                    </div>
                    <div className="popup-navigation">
                        {/* Disabled when viewing the oldest change */}
                        <button onClick={handlePrev} disabled={currentIndex === history.length - 1}>Previous</button>
                        {/* Disabled when viewing the latest change */}
                        <button onClick={handleNext} disabled={currentIndex === 0}>Next</button>
                    </div>
                    <button onClick={onClose} className="close-button">Close</button>
                </div>
            </div>
        );
    }


// Function to fetch and show history for a specific configuration key
    const handleShowHistory = (configKeyId) => {
        fetch(`${process.env.REACT_APP_API_URL}/game-config-history/${gameId}/${configKeyId}`)
            .then(response => response.json())
            .then(data => {
                setCurrentHistory(data); // Set the fetched history data
                setShowHistoryPopup(true); // Show the history modal
            })
            .catch(error => console.error('Error fetching configuration history:', error));
    };

    // Function to copy text to the clipboard
    const handleCopyToClipboard = (text) => {
        if (!navigator.clipboard) {
            console.error("Clipboard API not available.");
            return;
        }

        navigator.clipboard.writeText(text).then(() => {
            console.log("Copied to clipboard successfully!");
        }, (err) => {
            console.error("Failed to copy text to clipboard", err);
        });
    };

    
    // Function to handle configuration value changes
    const handleConfigChange = (event, configKey) => {
        const updatedConfigurations = configurations.map(config => {
            // Convert both null and empty string to an empty string for comparison
            const originalValue = config.originalValue === null ? "" : config.originalValue;
            const newValue = event.target.value;

            return config.key_name === configKey
                ? {
                    ...config,
                    editedValue: newValue,
                    isEdited: originalValue !== newValue,
                    provider_id: config.provider_id,
                    config_key_id: config.config_key_id,
                }
                : config;
        });
        setConfigurations(updatedConfigurations);
    };

    // Function to submit updated configurations
    const handleSubmit = (event) => {
        event.preventDefault();
        
        // Filter configurations that have been edited
        const editedConfigs = configurations.filter(config => config.isEdited).map(config => ({
            provider_id: config.provider_id,
            config_key_id: config.config_key_id,
            key_name: config.key_name,
            game_name: config.game_name,
            editedValue: config.editedValue,
            originalValue: config.originalValue,
        }));
        

        if (editedConfigs.length > 0) {
            setEditedConfigurations(editedConfigs);
            setShowConfirmationPopup(true); // Show the confirmation popup with changes ready for confirmation
        } else {
            console.log("No changes to save.");
        }
    };

    const handleConfirmChanges = () => {
        setIsSaving(true); // Disable the button and show the spinner

        const username = localStorage.getItem('userName'); // Retrieve the authentication token

        const payload = {
            gameId,
            username,
            game_name: `${gameDetails.game_name} ${gameDetails.company_name} ${gameDetails.platform_name}`,
            configurations: editedConfigurations.map(({ key_name, config_key_id, provider_id, editedValue, originalValue, game_name }) => ({
                key_name,
                config_key_id,
                provider_id,
                game_name: game_name,
                config_value: editedValue,
                original_value: originalValue,
            })),
        };

        fetch(`${process.env.REACT_APP_API_URL}/update-game-config`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                console.log("Configurations updated successfully:", data);
                setShowConfirmationPopup(false);
                setEditedConfigurations([]);
                setIsSaving(false); // Re-enable the button and hide the spinner
                fetchConfigurations(); // Refresh configurations from the backend
            })
            .catch(error => {
                console.error('Error updating configurations:', error);
                setIsSaving(false); // In case of error, also stop the spinner and re-enable the button
            });
    };


    const handleCancelChanges = () => {
        setShowConfirmationPopup(false);
        setEditedConfigurations([]);
    };


    function ConfirmationPopup({ isOpen, changes, onConfirm, onCancel }) {
        if (!isOpen) return null;

        return (
            <div className="popup-overlay">
                <div className="popup-content">
                    <h2>Confirm Changes</h2>
                    <div className="changes-table">
                        <div className="table-header">
                            <span className="table-column">Key Name</span>
                            <span className="table-column">Old Value</span>
                            <span className="table-column">New Value</span>
                        </div>
                        {changes.map((change, index) => (
                            <div key={index} className="table-row">
                                <span className="table-cell">{change.key_name}</span>
                                <span className="table-cell old-value">{change.originalValue}</span>
                                <span className="table-cell new-value">{change.editedValue}</span>
                            </div>
                        ))}
                    </div>
                    <div className="popup-actions">
                        <button
                            className={`confirm-btn ${isSaving ? 'saving' : ''}`}
                            onClick={onConfirm}
                            disabled={isSaving}>
                            {isSaving ? (
                                <>
                                    <i className="material-icons spin">autorenew</i>
                                    Saving...
                                </>
                            ) : (
                                "Confirm"
                            )}
                        </button>
                        <button onClick={onCancel} className="cancel-btn">Cancel</button>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <>
            <header className="header-container">
                <button
                    className="back-to-games-list-btn"
                    onClick={() => navigate('/games')}>
                    Back
                </button>
                <h1>
                    {gameDetails.game_name ? `${gameDetails.game_name} - ${gameDetails.company_name} - ${gameDetails.platform_name}` : "Loading game details..."}
                </h1>
                {canEdit && (
                    <button type="submit" form="config-form" className="save-changes-btn">
                        Save Changes
                    </button>
                )}
            </header>
            <div className="game-details-container"> {/* Main container added */}

                <form id="config-form" onSubmit={handleSubmit}>
                {
                    // Temporary grouping by provider_name just for rendering
                    Object.entries(configurations.reduce((acc, config) => {
                        // Use 'No Provider' as a fallback for configurations without a provider
                        const groupName = config.provider_name || 'No Provider';
                        if (!acc[groupName]) {
                            acc[groupName] = [];
                        }
                        acc[groupName].push(config);
                        return acc;
                    }, {})).map(([providerName, configs], index) => (
                        <div key={index} className="provider-group">
                            <h3 className="provider-name">{providerName}</h3>
                            {configs.map((config, configIndex) => (
                                <div key={configIndex} className="form-row">
                                    <label
                                        className={`key-name ${focusedKey === config.key_name ? 'focused' : ''}`}
                                        htmlFor={`config-${configIndex}`}>
                                        {config.key_name}:
                                    </label>
                                    <input
                                        id={`config-${configIndex}`}
                                        type="text"
                                        className={`input-box ${config.isEdited ? 'edited' : ''} ${focusedKey === config.key_name ? 'focused' : ''}`}
                                        value={config.editedValue || ""}
                                        onFocus={() => handleFocus(config.key_name)}
                                        onBlur={handleBlur}
                                        onChange={(event) => handleConfigChange(event, config.key_name)}
                                        onKeyPress={(event) => {
                                            if (event.key === 'Enter') {
                                                event.preventDefault();
                                                // Optionally, trigger save or other actions explicitly if needed
                                            }
                                        }}
                                        disabled={!canEdit}  // Conditionally disable input based on the admin status
                                    />
                                    <button type="button" onClick={() => handleCopyToClipboard(config.editedValue)}
                                            className="copy-btn" title="Copy Field">
                                        <i className="material-icons">content_copy</i>
                                    </button>
                                    <button type="button" onClick={() => handleShowHistory(config.config_key_id)}
                                            className="show-history-btn" title="Show History">
                                        <i className="material-icons">history</i>
                                    </button>
                                </div>
                            ))}
                        </div>
                    ))
                }
                <br></br>
                </form>
            </div>
            {showConfirmationPopup && (
                <ConfirmationPopup
                    isOpen={showConfirmationPopup}
                    changes={editedConfigurations}
                    onConfirm={handleConfirmChanges}
                    onCancel={handleCancelChanges}
                    isSaving={isSaving}
                />
            )}
            {showHistoryPopup && (
                <HistoryPopup
                    isOpen={showHistoryPopup}
                    history={currentHistory}
                    onClose={() => setShowHistoryPopup(false)}
                />
            )}
        </>
    );
};

export default GameDetailsPage;
