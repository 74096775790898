// ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation, matchPath } from 'react-router-dom';
import { useAuth } from './AuthContext';
import {permissions} from './permissions'; // Import the centralized permissions

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, isInitializing, userRoleName } = useAuth();
    const location = useLocation(); // Get the current route path

    // Wait for initialization to finish before rendering children or redirecting
    if (isInitializing) {
        return <div>Loading...</div>; // Or any other loading indicator
    }

    // Get allowed routes for the user's role from the centralized permissions
    const allowedRoutes = permissions[userRoleName]?.routes || [];

    // Check if the current route is allowed for the user's role
    const isAllowed = allowedRoutes.some(route => matchPath(route, location.pathname));

    // If not authenticated or not allowed, redirect to forbidden or home page
    if (!isAuthenticated) {
        return <Navigate to="/login" replace />; // Redirect to Login instead of root
    } else if (!isAllowed) {
        return <Navigate to="/forbidden" />;
    }

    return children; // Render the protected component
};

export default ProtectedRoute;
